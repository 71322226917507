import React, { useState } from 'react';
import WebHeader from '../../WebHeader/WebHeader';
import Sidebar from '../../Sidebar/Sidebar';
import WebFooter from '../../WebFooter/WebFooter';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { API_URL } from '../../../Config';

const EditRoomDatabase = () => {

    const location = useLocation();
    const roomDetails = location.state?.roomDetails || '';
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        roomName: roomDetails.roomName || '',
        available: roomDetails.available || '',
        price: roomDetails.price || '',
        roomSize: roomDetails.roomSize || '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const updateRoomDetails = async () => {
        try {
            const response = await fetch(API_URL + `/updateRoomDetails/${roomDetails.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                console.log('Room details updated successfully');
                window.alert('Room details updated successfully!');
                navigate('/RoomDatabase');
            } else {
                console.error('Error updating room details');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className='bg-[#F8F6F6] text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <WebHeader />

            <div className='hidden lg:block'>
                <Sidebar />
            </div>
            <br />

            <div className='mt-[70px] mx-[3%] lg:ml-[23%]'>

                <Link
                    to="/RoomDatabase"
                    className='text-[#008080] hover:underline'
                >Go Back</Link>

                <div className='bg-white rounded-lg p-[3%] lg:p-[2%] mb-[2vw] lg:h-[74vh]'>
                    <p className='font-semibold'>ROOM DATABASE</p>

                    <p className='mt-[2%] text-lg md:text-2xl lg:text-xl 2xl:text-3xl'>Room Database Details</p>
                    <hr className='my-[2%]' />

                    <div className='grid grid-cols-1 lg:grid-cols-2'>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Room Name*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='roomName' value={formData.roomName} onChange={handleInputChange} />
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Stock*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='available' value={formData.available} onChange={handleInputChange} />
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Price*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='price' value={formData.price} onChange={handleInputChange} />
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Room Size</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='roomSize' value={formData.roomSize} onChange={handleInputChange} />
                        </div>

                    </div>

                    <div className="flex justify-end mt-[2%]">
                        <button className="flex items-center bg-[#008080] text-white shadow-custom rounded-lg px-[3%] hover:scale-110" onClick={() => updateRoomDetails()}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <br />

            <WebFooter />
        </div>
    );
};

export default EditRoomDatabase;
