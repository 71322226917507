import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from "antd";
import { useAuth } from '../../AuthContext';



const Sidebar = () => {

    const { user } = useAuth();
    const username = user.user;

    const navigate = useNavigate();
    const location = useLocation();

    const handleDashboard = () => {
        navigate('/Dashboard');
    };

    const handleOfflineBooking = () => {
        navigate('/OfflineBooking');
    };

    const handleAllReservation = () => {
        navigate('/AllReservation');
    };

    const handleLogout = () => {
        try {
            sessionStorage.removeItem('user');
            window.location.reload(true);
            window.location.href = '/Login'
        } catch (error) {
            console.error('Error removing user data from localStorage:', error);
        }
    }

    const isActive = (path) => location.pathname === path;

    return (
        <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <div className='flex flex-col items-center bg-white border mt-[70px] 2xl:mt-[80px] h-full w-[20%] py-[2%] fixed overflow-y-auto'>

                <div>
                    <p>{username.username}</p>
                    <p className='text-gray-400 font-thin'>{username.role.toUpperCase()}</p>
                </div>

                <div className='flex flex-col mt-[2vw] text-gray-700'>
                    <button
                        className={`border my-[2.5%] px-3 py-2 rounded-lg ${isActive('/Dashboard') ? 'bg-teal-600 text-white' : 'hover:border-[#008080] hover:text-black'}`}
                        onClick={handleDashboard}
                    >
                        ANALYTICS
                    </button>

                    <button
                        className={`border my-[2.5%] px-3 py-2 rounded-lg ${isActive('/OfflineBooking') ? 'bg-teal-600 text-white' : 'hover:border-[#008080] hover:text-black'}`}
                        onClick={handleOfflineBooking}
                    >
                        OFFLINE BOOKING
                    </button>

                    <Tooltip title={<><a href='/CompanyDatabase' className='hover:underline' style={{ color: 'white' }}>Company Database</a><br /><a href='/UserDatabase' className='hover:underline' style={{ color: 'white' }}>User Database</a><br /><a href='/RoomDatabase' className='hover:underline' style={{ color: 'white' }}>Room Database</a><br /><a href='/RevenueDatabase' className='hover:underline' style={{ color: 'white' }}>Revenue Database</a></>} placement="right">
                        <div
                            className={`border my-[2.5%] px-3 py-2 rounded-lg text-center ${isActive('/CompanyDatabase') || isActive('/UserDatabase') || isActive('/RoomDatabase') || isActive('/RevenueDatabase') ? 'bg-teal-600 text-white' : 'hover:border-[#008080] hover:text-black'}`}
                        >
                            ALL DATABASE
                        </div>
                    </Tooltip>

                    <button
                        className={`border my-[2.5%] px-3 py-2 rounded-lg ${isActive('/AllReservation') ? 'bg-teal-600 text-white' : 'hover:border-[#008080] hover:text-black'}`}
                        onClick={handleAllReservation}
                    >
                        ALL RESERVATIONS
                    </button>
                    <button
                        className='border hover:border-[#008080] hover:text-black my-[2%] 2xl:my-[3%] px-3 py-2 2xl:py-3 rounded-lg mt-[10vw] 2xl:mt-[16vw]'
                        onClick={handleLogout}
                    >
                        LOGOUT
                    </button>
                </div>

            </div>
        </div>
    );
};

export default Sidebar;