import React, { useEffect, useState } from 'react';
import WebHeader from '../WebHeader/WebHeader';
import Sidebar from '../Sidebar/Sidebar';
import { useLocation } from 'react-router-dom';
import { differenceInCalendarDays, isValid, parse } from 'date-fns';
import { API_URL } from '../../Config';
import { useAuth } from '../../AuthContext';
import axios from 'axios';


const Checkout = () => {

    const location = useLocation();
    const { user } = useAuth();
    const username = user.user;
    const check_in = location.state?.check_in || '';
    const check_out = location.state?.check_out || '';
    const roomPrice = location.state?.roomPrice || '';
    const roomDetails = location.state?.roomDetails || '';
    const roomName = roomDetails.roomName || '';
    const roomCount = location.state?.roomCount || '';
    const bookRide = location.state?.bookRide || false;
    const childaccompanying = location.state?.childaccompanying || false;
    const selectedPriceOption = location.state?.selectedPriceOption || '';
    const adults = location.state?.adults || 1;
    const children = location.state?.children || 0;


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const [missingFields, setMissingFields] = useState([]);

    const imagess = [
        "../assets/Room-1-View-1.webp",
        // "../assets/Room-1-View-5.webp",
        "../assets/Room-1-View-6.webp",
        "../assets/Room-1-View-7.webp",
        "../assets/Room-2-View-6.webp"
    ];
    const imagesExecutiveRoom = [
        '../assets/executive_room_img1.webp',
        '../assets/Executive-Room-01.webp',
        '../assets/Executive-Room-02.webp',
        '../assets/Executive-Room-03.webp'
    ];

    const imagesClubRoom = [
        "../assets/Room-1-View-1.webp",
        "../assets/Room-1-View-5.webp",
        "../assets/Room-1-View-6.webp",
        "../assets/Room-1-View-7.webp",
    ];

    const imagesSuperiorRoom = [
        '../assets/superior_room_img1.webp',
        '../assets/Room-2-View-6.webp',
        '../assets/Room 2 View 10.webp',
        '../assets/_MG_6983.webp'
    ];

    const imagesPenthouseRoom = [
        '../assets/penthouse_room_img1.webp',
        '../assets/Penthouse suite 020.webp',
        '../assets/Penthouse suite 017.webp',
        '../assets/Penthouse suite 006.webp'
    ];

    const images = roomName === 'Executive Room' ? imagesExecutiveRoom : roomName === 'Club Room' ? imagesClubRoom : roomName === 'Superior Room' ? imagesSuperiorRoom : roomName === 'Penthouse Suite' ? imagesPenthouseRoom : imagess

    const [couponCode, setCouponCode] = useState('');
    const [couponApplied, setCouponApplied] = useState(false);
    const [discountCode, setDiscountCode] = useState('');
    const [discountApplied, setDiscountApplied] = useState(false);
    
    const handleCouponCodeChange = (e) => {
        setCouponCode(e.target.value);
    };
    const handleDiscountCodeChange = (e) => {
        setDiscountCode(e.target.value);
    };
    const applyCoupon = () => {
        if (couponCode.toUpperCase() === 'WELCOME') {
            const discountAmount = totalPrice * 0.1;
            setTotalPrice(totalPrice - discountAmount);
            setCouponApplied(true);
        } else {
            alert('Invalid coupon code');
        }
    };

    const applyDiscount = () => {
        if (discountCode) {
            const isPercentage = discountCode.toString().includes('%');
            const discountValue = parseFloat(discountCode);
            if (isNaN(discountValue)) {
                alert('Invalid discount code');
                return;
            }
            const discountAmount = isPercentage ? totalPrice * (discountValue / 100) : totalPrice * (discountValue / 100);
            setTotalPrice(totalPrice - discountAmount);
            setDiscountApplied(true);
        } else {
            alert('Please enter a discount percentage');
        }
    };

    const [totalPrice, setTotalPrice] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    const [nights, setNights] = useState(0);
    const [extraPrice, setExtraPrice] = useState(0);
    const [ridePrice, setRidePrice] = useState(0);
    const [childPrice, setChildPrice] = useState(0);

    useEffect(() => {
        const calculateNights = () => {
            if (bookRide) {
                setRidePrice(500);
            }
            if (childaccompanying) {
                setChildPrice(200);
            }
            const extraPrices = ridePrice + childPrice;
            setExtraPrice(extraPrices);
            const checkInDate = parse(check_in, 'dd/MM/yyyy', new Date());
            const checkOutDate = parse(check_out, 'dd/MM/yyyy', new Date());

            if (!isValid(checkInDate)) {
                console.error("Invalid check-in date");
            }
            if (!isValid(checkOutDate)) {
                console.error("Invalid check-out date");
            }
            const numberOfNights = differenceInCalendarDays(checkOutDate, checkInDate);
            const baseTotalPrice = numberOfNights * roomCount * roomPrice;
            const taxAmount = baseTotalPrice * 0.18;
            const totalPriceWithTax = baseTotalPrice + taxAmount + extraPrice;

            setNights(numberOfNights);
            setTaxAmount(taxAmount);
            setTotalPrice(totalPriceWithTax);
        };

        if (check_in && check_out) {
            calculateNights();
        }
    }, [check_in, check_out, roomPrice, bookRide, childPrice, ridePrice, childaccompanying, extraPrice, roomCount]);

    const handleCouponRemove = () => {
        setTotalPrice((nights * roomPrice) + (nights * roomPrice * 0.18) + extraPrice)
        setCouponCode('');
        setCouponApplied(false);
    }
    const handleDiscountRemove = () => {
        setTotalPrice((nights * roomPrice) + (nights * roomPrice * 0.18) + extraPrice)
        setDiscountCode('');
        setDiscountApplied(false);
    }
    const handleConfirmAndBook = () => {
        const specialRequests = document.querySelector('textarea[name="specialRequests"]').value;
        const { name, email, phone } = formData;

        const missingFields = [];
        if (!name.trim()) {
            missingFields.push('Name');
        }
        if (!email.trim()) {
            missingFields.push('Email');
        }
        if (!phone.trim()) {
            missingFields.push('Phone Number');
        }
        setMissingFields(missingFields);

        const bookingDetails = {
            roomId: roomDetails.id,
            roomName: roomDetails.roomName,
            nights: `${roomCount} Room x ${nights} Nights`,
            name: document.querySelector('input[name="name"]').value,
            email: document.querySelector('input[name="email"]').value,
            phone: document.querySelector('input[name="phone"]').value,
            price: (nights * roomPrice).toFixed(2),
            taxes: taxAmount.toFixed(2),
            extra: extraPrice,
            total: totalPrice,
            companyName: username.companyName,
            memberId: username.memberId,
            memberStatus: username.status,
            role: username.role,
            bookRide: bookRide,
            childaccompanying: childaccompanying,
            checkIn: check_in,
            checkOut: check_out,
            specialRequests: specialRequests,
            breakfast: selectedPriceOption,
            adults,
            childPrice,
            roomCount: roomCount,
            transactionId: 'T' + Date.now(),
            rewardPoints: Math.floor(totalPrice / 100)

        };

        if (missingFields.length === 0) {
            handleBookingSuccess(bookingDetails);
        }
    };

    const formatDate = (dateString) => {
        const parts = dateString.split('/');
        if (parts.length === 3) {
            const [day, month, year] = parts;
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        } else {
            throw new Error('Invalid date format');
        }
    };
    // Function to handle booking success
    const handleBookingSuccess = async (bookingDetails) => {
        const bookingData = {
            roomId: bookingDetails.roomId,
            name: bookingDetails.name,
            email: bookingDetails.email,
            phone: bookingDetails.phone,
            checkIn: formatDate(bookingDetails.checkIn),
            checkOut: formatDate(bookingDetails.checkOut),
            roomName: bookingDetails.roomName,
            nights: `${roomCount} Room x ${nights} Nights`,
            price: (nights * roomCount * roomPrice).toFixed(2),
            taxes: taxAmount.toFixed(2),
            extra: extraPrice,
            total: totalPrice,
            companyName: bookingDetails.companyName,
            memberId: bookingDetails.memberId,
            memberStatus: bookingDetails.memberStatus,
            role: bookingDetails.role,
            bookRide: bookingDetails.bookRide,
            childaccompanying: bookingDetails.childaccompanying,
            specialRequests: bookingDetails.specialRequests,
            breakfast: selectedPriceOption,
            adults: adults,
            children: children,
            roomCount: bookingDetails.roomCount,
            transactionId: bookingDetails.transactionId,
            rewardPoints: bookingDetails.rewardPoints

        };
        try {
            await axios.post(API_URL + '/payment', bookingData).then(res => {
                console.log(res.data.data)
                if (res.data.success === true) {
                    console.log(res.data.data.instrumentResponse.redirectInfo.url)
                    let url = res.data.data.instrumentResponse.redirectInfo.url;

                    // url = url.replace('/pg?', '/pg2?');
                    console.log("url==>", url);
                    window.location.href = url;
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <div className='bg-[#F8F6F6] text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <WebHeader />

            <div className='hidden lg:block'>
                <Sidebar />
            </div>
            <br />

            <div className='mt-[70px] mx-[3%] lg:ml-[23%]'>

                {/* Section 1 starts from here */}
                <div className='grid grid-cols-2 lg:grid-cols-4'>
                    {images.map((image, index) => (
                        <img
                            key={index}
                            className='rounded-lg lg:rounded-xl px-[3%] cursor-pointer'
                            src={image}
                            alt={`Thumbnail ${index}`}
                        />
                    ))}
                </div>
                {/* Section 1 ends here */}

                {/* Grid section starts from here */}
                <div className='mt-[5%] lg:mt-[3%] lg:mx-[5%]'>
                    <div className='grid grid-cols-1 lg:grid-cols-2'>

                        <div>
                            <div className='border rounded-xl lg:mx-[5%] p-[3%] bg-white'>
                                <img className='rounded-lg' src={images[0]} alt="Img" />

                                <p className='font-semibold my-[2%]'>{roomName}</p>

                                <div className='flex'>
                                    <p className='text-[12px] md:text-sm 2xl:text-lg'>Kalavad Rd, Near Everest Park, Jai Bhimnagar, Nana Mava, Rajkot.</p>
                                </div>

                                <div className='grid grid-cols-1 lg:grid-cols-2 my-[3%]'>
                                    <div className='flex flex-col mx-[2%]'>
                                        <label className='text-[12px] md:text-sm 2xl:text-lg font-semibold'>Check In</label>
                                        <label className='border rounded-lg outline-[#008080] p-[1%]'>{check_in}</label>
                                    </div>

                                    <div className='flex flex-col mx-[2%]'>
                                        <label className='text-[12px] md:text-sm 2xl:text-lg font-semibold'>Check Out</label>
                                        <label className='border rounded-lg outline-[#008080] p-[1%]'>{check_out}</label>
                                    </div>
                                </div>

                                <div>
                                    <p className='font-semibold my-[2%]'>Price Details</p>
                                    <div className='flex justify-between'>
                                        <p className='text-[12px] md:text-sm 2xl:text-lg my-[2%]'>{`${roomCount} Room x ${nights} Nights`}</p>
                                        <p className='text-[12px] md:text-sm 2xl:text-lg my-[2%]'>{(nights * roomCount * roomPrice).toFixed(2)}</p>
                                    </div>

                                    <div className='flex justify-between'>
                                        <p className='text-[12px] md:text-sm 2xl:text-lg my-[2%]'>Taxes</p>
                                        <p className='text-[12px] md:text-sm 2xl:text-lg my-[2%]'>{taxAmount.toFixed(2)}</p>
                                    </div>

                                    <div className='flex justify-between'>
                                        <p className='text-[12px] md:text-sm 2xl:text-lg my-[2%]'>Extra</p>
                                        <p className='text-[12px] md:text-sm 2xl:text-lg my-[2%]'>{extraPrice}</p>
                                    </div>
                                </div>

                                <div className='flex flex-col my-[1%]'>
                                    <label className='text-[12px] md:text-sm 2xl:text-lg font-semibold'>Add Promo Code </label>

                                    <div className='flex justify-between w-full'>
                                        <input type="text" value={couponCode}
                                            onChange={handleCouponCodeChange}
                                            disabled={couponApplied}
                                            style={{ opacity: couponApplied ? '0.5' : '1', cursor: couponApplied ? 'not-allowed' : 'pointer' }}
                                            className='border bg-transparent rounded-lg outline-[#008080] p-[1%] w-[80%]' />

                                        <button onClick={applyCoupon}
                                            disabled={couponApplied}
                                            style={{ opacity: couponApplied ? '0.5' : '1', cursor: couponApplied ? 'not-allowed' : 'pointer' }}
                                            className='flex items-center rounded-lg font-medium bg-[#008080] text-white hover:text-[#008080] hover:bg-white border-[#008080] border-2 hover:border-[#008080] px-[3%] py-[1%] text-sm'>
                                            Apply
                                        </button>
                                    </div>
                                    {couponApplied ? <p className='text-[12px] md:text-sm 2xl:text-lg my-[2%]' onClick={handleCouponRemove}>remove applied coupon</p> : ''}
                                </div>

                                <div className='flex flex-col my-[1%]'>
                                    <label className='text-[12px] md:text-sm 2xl:text-lg font-semibold'>Add Discount (%)</label>

                                    <div className='flex justify-between w-full'>
                                        <input type="text" value={discountCode}
                                            onChange={handleDiscountCodeChange}
                                            disabled={discountApplied}
                                            style={{ opacity: discountApplied ? '0.5' : '1', cursor: discountApplied ? 'not-allowed' : 'pointer' }}
                                            className='border bg-transparent rounded-lg outline-[#008080] p-[1%] w-[80%]' />

                                        <button onClick={applyDiscount}
                                            disabled={discountApplied}
                                            style={{ opacity: discountApplied ? '0.5' : '1', cursor: discountApplied ? 'not-allowed' : 'pointer' }}
                                            className='flex items-center rounded-lg font-medium bg-[#008080] text-white hover:text-[#008080] hover:bg-white border-[#008080] border-2 hover:border-[#008080] px-[3%] py-[1%] text-sm'>
                                            Apply
                                        </button>
                                    </div>
                                    {discountApplied ? <p className='text-[12px] md:text-sm 2xl:text-lg my-[2%]' onClick={handleDiscountRemove}>remove applied discount</p> : ''}
                                </div>

                                <div className='flex justify-between'>
                                    <p className='font-bold mt-[3%]'>Total</p>
                                    <p className='font-bold mt-[3%]'>{totalPrice.toFixed(2)}</p>
                                </div>
                            </div>
                        </div>

                        <div className='lg:mx-[5%] mt-[5%] lg:mt-0'>

                            <div className='border rounded-xl p-[3%] bg-white'>
                                <div className='flex items-center justify-between'>
                                    <p className='font-semibold'>1. Contact Information</p>
                                </div>


                                <div>
                                    <div className='flex flex-col my-[1%]'>
                                        <label className='text-[12px] md:text-sm 2xl:text-lg font-medium'>Name</label>
                                        <input type="text" name='name' className='border bg-transparent rounded-lg outline-[#008080] p-[1%]' value={formData.name} onChange={handleChange} />
                                        {missingFields.includes('Name') && <p className="text-red-500">Please enter your name</p>}
                                    </div>


                                    <div className='flex flex-col my-[1%]'>
                                        <label className='text-[12px] md:text-sm 2xl:text-lg font-medium'>Email</label>
                                        <input type="text" name='email' className='border bg-transparent rounded-lg outline-[#008080] p-[1%]' value={formData.email} onChange={handleChange} />
                                        {missingFields.includes('Email') && <p className="text-red-500">Please enter your email</p>}
                                    </div>

                                    <div className='flex flex-col my-[1%]'>
                                        <label className='text-[12px] md:text-sm 2xl:text-lg font-medium'>Phone Number</label>
                                        <input type="text" name='phone' className='border bg-transparent outline-[#008080] rounded-lg p-[1%]' value={formData.phone} onChange={handleChange} />
                                        {missingFields.includes('Phone Number') && <p className="text-red-500">Please enter your phone number</p>}
                                    </div>

                                    <div className='flex flex-col mt-[2%]'>
                                        <label className="text-[12px] md:text-sm 2xl:text-lg font-medium">Any special requests?</label>
                                        <textarea name="specialRequests" type="text" className='border bg-transparent rounded-lg outline-[#008080] p-[1%]' />
                                    </div>

                                </div>

                            </div>

                            <div className='border rounded-xl mt-[3%] p-[3%] bg-white'>
                                <div className='flex items-center justify-between' >
                                    <p className='font-semibold'>2. Cancellation Policy</p>
                                </div>


                                <div>
                                    <div className='flex flex-col my-[1%] mx-[2%]'>
                                        <p className='text-[12px] md:text-sm 2xl:text-lg'>
                                            By selecting the button below, I agree to the Erasmus Life Housing rules,  Booking and Refund
                                            rules.
                                        </p><br />

                                        <p className='text-[12px] md:text-sm 2xl:text-lg'>
                                            I also agree to the
                                            <span className='text-[#25409C]'> updated Terms of Service, Payments Terms of Service,</span>
                                            and I acknowledge the
                                            <span className='text-[#25409C]'> Privacy Policy.</span>
                                        </p>

                                        <div className='flex justify-end mt-[3%]'>
                                            <button onClick={handleConfirmAndBook} className='flex items-center rounded-lg font-medium bg-[#008080] text-white hover:text-[#008080] hover:bg-white border-[#008080] border-2 hover:border-[#008080] px-[3%] py-[1%] text-sm'>
                                                Confirm and Book
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                {/* Grid section ends here */}

            </div>
            <br /><br />
        </div>
    );
};

export default Checkout;