import React, { useCallback, useEffect, useState } from 'react';
import { RiUserAddLine } from "react-icons/ri";
import { GoSearch } from "react-icons/go";
import { Link, useNavigate } from 'react-router-dom';
import { BsInfoLg } from "react-icons/bs";
// import { RiDeleteBin5Line } from "react-icons/ri";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import WebHeader from '../WebHeader/WebHeader';
import Sidebar from '../Sidebar/Sidebar';
import WebFooter from '../WebFooter/WebFooter';
import { API_URL } from '../../Config';
// import { API_URL } from '../../../Config';

const AllReservation = () => {

    const navigate = useNavigate();
    const [bookings, setBookings] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const handleOfflineBooking = () => {
        navigate('/OfflineBooking');
    };

    const fetchBookings = useCallback(async () => {
        try {
          const response = await fetch(API_URL + '/reservationDetails', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              role: 'admin'
            }),
          });
          let data = await response.json();
    
          setBookings(data);
          localStorage.setItem('bookings', JSON.stringify(data));
        } catch (error) {
          console.error('Error fetching bookings:', error);
        }
      }, []);

    useEffect(() => {
        fetchBookings();
    }, [fetchBookings]);

    const filteredCompany = bookings.filter(i => {
        const searchQueryLower = searchQuery.toLowerCase();
        i.roomName = i.roomName ? i.roomName.toString() : '';
        i.nights = i.nights ? i.nights.toString() : '';
        i.email = i.email ? i.email.toString() : '';
        i.breakfast = i.breakfast ? i.breakfast.toString() : '';
        i.total = i.total ? i.total.toString() : '';
        i.companyName = i.companyName ? i.companyName.toString() : '';

        return searchQuery === '' ||
            i.roomName.toLowerCase().includes(searchQueryLower) ||
            i.nights.toLowerCase().includes(searchQueryLower) ||
            i.email.toLowerCase().includes(searchQueryLower) ||
            i.breakfast.toLowerCase().includes(searchQueryLower) ||
            i.total.toLowerCase().includes(searchQueryLower) ||
            i.companyName.toLowerCase().includes(searchQueryLower);
    }
    );

    const tableTitles = ['Room Name', 'Nights', 'Email ID', 'Meal Plan', 'Total', 'Company Name', 'Method', 'Action'];

    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredCompany.length > 0 ? filteredCompany.slice(indexOfFirstRow, indexOfLastRow) : [];

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const Pagination = ({ rowsPerPage, totalRows, paginate, currentPage }) => {
        const totalPages = Math.ceil(totalRows / rowsPerPage);

        return (
            <nav className='flex justify-end my-[2%]'>
                <ul className='flex items-center'>
                    {currentPage > 1 && (
                        <li className='mx-1'>
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                className='p-1 bg-gray-200 text-xs rounded-full'>
                                <MdKeyboardArrowLeft />
                            </button>
                        </li>
                    )}
                    <li className='mx-1'>
                        <span className='px-2 py-2 text-xs'>
                            {currentPage} / {totalPages}
                        </span>
                    </li>
                    {currentPage < totalPages && (
                        <li className='mx-1'>
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                className='p-1 bg-gray-200 text-xs rounded-full'>
                                <MdKeyboardArrowRight />
                            </button>
                        </li>
                    )}
                </ul>
            </nav>
        );
    };

    const handleEditBooking = (row) => {
        navigate('/EditReservation', { state: { bookingDetails: row } });
    }

    return (
        <div className='bg-[#F8F6F6] text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <WebHeader />

            <div className='hidden lg:block'>
                <Sidebar />
            </div>
            <br />

            <div className='mt-[70px] mx-[3%] lg:ml-[23%] 2xl:mb-[6%]'>

                <Link
                    to="/Dashboard"
                    className='text-[#008080] hover:underline'
                >Go Back</Link>

                <div className={`bg-white rounded-lg p-[3%] lg:p-[2%] lg:mb-[3vw] ${rowsPerPage <= 6 ? 'h-[77vh]' : 'h-auto'}`}>

                    <div className='flex flex-col lg:flex-row justify-between lg:items-center'>
                        <div className='flex'>
                            <div className='flex items-center'>
                                ALL RESERVATIONS
                                <button onClick={handleOfflineBooking}><RiUserAddLine className='text-xl md:text-2xl 2xl:text-3xl text-[#008080] ml-5 border border-[#008080] rounded-full p-0.5 hover:bg-[#008080] hover:text-white' /></button>
                            </div>
                        </div>

                        <div className='flex my-[4%] lg:my-0'>
                            <div className="flex items-center">
                                <div className="flex rounded-lg shadow-sm">
                                    <div className="flex w-10 items-center rounded-l-lg justify-center border-r border-gray-200 bg-white p-0">
                                        <GoSearch className='text-teal-600 ' />
                                    </div>
                                    <input
                                        type="text"
                                        className="w-full max-w-[160px] bg-white pl-2 text-base font-semibold outline-0"
                                        placeholder='Search here...'
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                            </div>
                            <button className="download_btn pixel_corners">
                                <div className="button_content_download_btn">
                                    <div className="svg_container_download_btn">
                                        <svg
                                            className="download_icon_download_btn"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479 6.908l-4-4h3v-4h2v4h3l-4 4z"
                                            ></path>
                                        </svg>
                                    </div>
                                    <div className="text_container_download_btn">
                                        <div className="text_download_btn">Download</div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>

                    <div>
                        <div className='my-[2%] overflow-x-auto'>
                            <table className='w-full border-collapse text-gray-700 text-[14px] 2xl:text-[18px]'>
                                <thead className='bg-[#eeffff] text-black'>
                                    <tr>
                                        {tableTitles.map((tableTitle, index) => (
                                            <th key={index} className='border-b p-2 text-left'>
                                                {tableTitle}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentRows.map((row, index) => (
                                        <tr key={index} className={`${index % 2 === 0 ? 'bg-[#F7F7F7]' : 'bg-white'}`}>
                                            <td className='border-b p-2 text-left'>{row.roomName}</td>
                                            <td className='border-b p-2 text-left'>{row.nights}</td>
                                            <td className='border-b p-2 text-left'>{row.email}</td>
                                            <td className='border-b p-2 text-left'>{row.breakfast}</td>
                                            <td className='border-b p-2 text-left'>{row.total}</td>
                                            <td className='border-b p-2 text-left'>{row.companyName}</td>
                                            <td className='border-b p-2 text-left'>{row.merchantTransactionId ? 'PhonePe' : 'Offline'}</td>
                                            <td className='border-b p-2 text-center'>
                                                <button onClick={() => handleEditBooking(row)} className="text-[18px] rounded hover:border-l hover:border-r hover:border-sky-500">
                                                    <BsInfoLg />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            rowsPerPage={rowsPerPage}
                            totalRows={filteredCompany.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                        {/* {isHandle_Delete && (
                            <>
                                <div className="overlay_Delete" onClick={closeForm_Delete}></div>
                                <div className="popup_form_Delete">
                                    <span className="close_Delete" onClick={closeForm_Delete}>&times;</span>
                                    <form>
                                        <div>
                                            <p className='text-white mb-[5%]'>Are you sure you want to delete this?</p>
                                            <button className='bg-white text-[#008080] rounded-lg p-[1%] w-[20%] hover:scale-90 mr-[2%]'>Cancel</button>
                                            <button className='bg-[#008080] text-white rounded-lg p-[1%] w-[20%] hover:scale-90'>Yes</button>
                                        </div>
                                    </form>
                                </div>
                            </>
                        )} */}
                    </div>
                </div>
            </div>
            <br />
            <WebFooter />
        </div>
    );
};

export default AllReservation;