import React, { useCallback, useEffect, useRef, useState } from 'react';
import WebHeader from '../WebHeader/WebHeader';
import Sidebar from '../Sidebar/Sidebar';
import WebFooter from '../WebFooter/WebFooter';
import { useNavigate } from 'react-router-dom';

import { DateRange } from 'react-date-range';
import format from 'date-fns/format'
import { addDays } from 'date-fns'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { API_URL } from '../../Config';

const OfflineBooking = () => {

    const navigate = useNavigate();
    const [availability, setAvailability] = useState([]);
    const [rooms, setRooms] = useState(() => {
        const storedRoomsData = localStorage.getItem('roomsData');
        return storedRoomsData ? JSON.parse(storedRoomsData) : [];
    });

    const [isLoading, setIsLoading] = useState(false);
    const [countExecutive, setCountExecutive] = useState(1);
    const [countClub, setCountClub] = useState(1);
    const [countSuperior, setCountSuperior] = useState(1);
    const [countPenthouse, setCountPenthouse] = useState(1);

    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 1),
            key: 'selection'
        }
    ])

    const handleSelectedRoom = (room) => {
        navigate('/BookRoom', { state: { roomPrice: room.price, check_in: range[0].startDate.toLocaleDateString(), check_out: range[0].endDate.toLocaleDateString(), roomDetails: room, countExecutive, countClub, countSuperior, countPenthouse, adults, children } });
    };

    const handleSearchClick = () => {
        checkAvailability(range[0].startDate, range[0].endDate, countExecutive, countClub, countSuperior, countPenthouse);
    };
    const checkAvailability = async (startDate, endDate, countExecutive, countClub, countSuperior, countPenthouse) => {
        setIsLoading(true);
        if (!startDate || !endDate) {
            console.error("Invalid date range");
            return;
        }

        const response = await fetch(API_URL + `/bookingAvailabilityController?startDate=${startDate.toISOString().split('T')[0]}&endDate=${endDate.toISOString().split('T')[0]}&countExecutive=${countExecutive}&countClub=${countClub}&countSuperior=${countSuperior}&countPenthouse=${countPenthouse}`);
        const data = await response.json();
        setAvailability(data.availability);
        setIsLoading(false)
    };

    // Function to get room availability
    const getRoomAvailability = (roomId) => {
        const room = availability.find(avail => avail.roomId === roomId);
        return room ? room.available : 0;
    };

    useEffect(() => {
        const fetchRooms = async () => {
            try {
                const response = await fetch(API_URL + '/roomsController'); // Replace with your actual backend endpoint
                const data = await response.json();
                setRooms(data);
                localStorage.setItem('roomsData', JSON.stringify(data));

            } catch (error) {
                console.error('Error fetching rooms:', error);
            }
        };

        fetchRooms();
        checkAvailability(range[0].startDate, range[0].endDate, countExecutive, countClub, countSuperior, countPenthouse);
    }, [range, countExecutive, countClub, countSuperior, countPenthouse]);


    const [setOpen] = useState(false)

    const refOne = useRef(null)

    const hideOnEscape = useCallback((e) => {
        if (e.key === "Escape") {
            setOpen(false)
        }
    }, [setOpen])

    const hideOnClickOutside = useCallback((e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false)
        }
    }, [setOpen])

    useEffect(() => {
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
    }, [hideOnClickOutside, hideOnEscape])


    const [rooms_guests, setRooms_guests] = useState(false);

    const openForm_rooms_guests = () => {
        setRooms_guests(true);
    };

    const closeForm_rooms_guests = () => {
        setRooms_guests(false);
    };

    const [Calendar, setCalendar] = useState(false);

    const openForm_Calendar = () => {
        setCalendar(true);
    };

    const closeForm_Calendar = () => {
        setCalendar(false);
    };

    const incrementCount = (id) => {
        switch (id) {
            case 1:
                return setCountExecutive(countExecutive + 1);
            case 2:
                return setCountClub(countClub + 1);
            case 3:
                return setCountSuperior(countSuperior + 1);
            case 4:
                return setCountPenthouse(countPenthouse + 1);
            default:
                // Handle unexpected input here
                break;
        }
    };

    const decrementCount = (id) => {
        switch (id) {
            case 1:
                if (countExecutive > 0) {
                    return setCountExecutive(countExecutive - 1);
                }
                break;
            case 2:
                if (countClub > 0) {
                    return setCountClub(countClub - 1);
                }
                break;
            case 3:
                if (countSuperior > 0) {
                    return setCountSuperior(countSuperior - 1);
                }
                break;
            case 4:
                if (countPenthouse > 0) {
                    return setCountPenthouse(countPenthouse - 1);
                }
                break;
            default:
                // Handle unexpected input here
                break;
        }
    };

    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);

    const increment = (setter, value, max = Infinity) => value < max && setter(value + 1);
    const decrement = (setter, value) => value > 0 && setter(value - 1);

    return (
        <div className='bg-[#F8F6F6] text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <WebHeader />

            <div className='hidden lg:block'>
                <Sidebar />
            </div>
            <br />

            <div className='mt-[70px] mx-[3%] lg:ml-[23%]'>

                <div className=' p-[3%] lg:p-[2%] mb-[2vw]'>
                    <p className='font-bold text-center text-xl md:text-2xl 2xl:text-3xl text-[#6D6148]'>Offline Booking</p>
                    {isLoading ? (
                        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
                        </div>
                    ) : null}
                    <div className='border bg-white flex items-center justify-center mt-[5%] lg:mt-[2%] p-[2%] lg:p-[1%] rounded-lg lg:mx-[25%]'>

                        <div className='px-[2%]'>
                            <button className='text-center text-sm 2xl:text-lg leading-4' onClick={openForm_Calendar}>
                                {`${range[0].startDate.toLocaleDateString()} - ${range[0].endDate.toLocaleDateString()}  ` || 'CHECK-IN & CHECK-OUT'}
                            </button>

                            {Calendar && (
                                <>
                                    <div className="overlay_calendar" onClick={closeForm_Calendar}></div>
                                    <div className="popupForm_calendar">
                                        <span className="close_calendar" onClick={closeForm_Calendar}>&times;</span>
                                        <p className='text-left'><b>CALENDAR</b></p><br />
                                        <div>
                                            <div className="calendarWrap bg-transparent outline-none rounded border">
                                                <input
                                                    value={`${format(range[0].startDate, "dd/MM/yyyy")} - ${format(range[0].endDate, "dd/MM/yyyy")}`}
                                                    readOnly
                                                    className="inputBox bg-transparent w-[100%] text-center outline-none h-8 lg:h-9 2xl:h-12"
                                                    onClick={() => setOpen(open => !open)}
                                                />
                                                <div className='calendar_bg shadow-custom -ml-0'>
                                                    {/* {open && */}
                                                    <DateRange
                                                        onChange={item => setRange([item.selection])}
                                                        editableDateInputs={true}
                                                        moveRangeOnFirstSelection={false}
                                                        ranges={range}
                                                        rangeColors={['#008080']}
                                                        months={1}
                                                        direction="horizontal"
                                                        className=""
                                                    />
                                                    {/* } */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        <hr className='rotate-90 w-[2em]' />

                        <div className='px-[2%]'>
                            <button className='text-center text-sm 2xl:text-lg leading-4' onClick={openForm_rooms_guests}>
                            {adults} Adult, {children} Children
                            </button>

                            {rooms_guests && (
                                <>
                                    <div className="overlay_rooms_guests" onClick={closeForm_rooms_guests}></div>
                                    <div className="popupForm_rooms_guests">
                                        <span className="close_rooms_guests" onClick={closeForm_rooms_guests}>&times;</span>
                                        <p className='text-left'><b>BOOK A STAY</b></p><br />
                                        <div>
                                            <div className='border-[1px] border-black px-1 py-2 rounded-lg'>
                                            {adults} Adults, {children} Children
                                            </div>

                                            <div className='flex justify-between border-[1px] my-5 rounded-lg'>
                                                <input className='px-1 py-2' type="text" name="adults" value={`${adults} Adults`} readOnly />

                                                <div className='flex items-center'>
                                                    <button onClick={() => decrement(setAdults, adults)} className='flex items-center border-[1px] text-[#008080] h-8 w-8 mx-1 justify-center rounded-lg text-2xl 2xl:text-4xl hover:border-[2px] hover:border-[#008080]'>-</button>
                                                    <button onClick={() => increment(setAdults, adults)} className='flex items-center border-[1px] text-[#008080] h-8 w-8 mx-1 justify-center rounded-lg text-2xl 2xl:text-4xl hover:border-[2px] hover:border-[#008080]'>+</button>
                                                </div>
                                            </div>

                                            <div className='flex justify-between border-[1px] my-5 rounded-lg'>
                                                <input className='px-1 py-2' type="text" name="children" value={`${children} Children`} readOnly />

                                                <div className='flex items-center'>
                                                    <button onClick={() => decrement(setChildren, children)} className='flex items-center border-[1px] text-[#008080] h-8 w-8 mx-1 justify-center rounded-lg text-2xl 2xl:text-4xl hover:border-[2px] hover:border-[#008080]'>-</button>
                                                    <button onClick={() => increment(setChildren, children)} className='flex items-center border-[1px] text-[#008080] h-8 w-8 mx-1 justify-center rounded-lg text-2xl 2xl:text-4xl hover:border-[2px] hover:border-[#008080]'>+</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        <hr className='rotate-90 w-[2em]' />
                        <div className='px-[2%]'>
                            <button className="grid mx-auto items-center h-full text-[#008080] group-hover:text-white" onClick={handleSearchClick}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <hr className='my-[8%] lg:my-[3%]' />

                    {rooms.map((room, index) => (
                        <div className='grid grid-cols-1 lg:grid-cols-2 items-center border rounded-lg my-[4%] lg:my-[2%]'>
                            <div>
                                <img src={room.roomImg && URL.createObjectURL(new Blob([new Uint8Array(room.roomImg.data)]))} alt="Room" />
                            </div>

                            <div className='px-[3%] lg:px-[5%]'>
                                <p className='font-medium text-lg md:text-2xl lg:text-xl 2xl:text-3xl mt-[3%] lg:mt-0'>
                                    {room.roomName}
                                </p>
                                <p className='my-[3%]'>
                                 Rs.{room.price}<br />
                                </p>
                                <p>{room.roomContent}</p>
                                <div className='flex items-center space-x-4 my-4'>
                                    <button
                                        className='bg-[#008080] text-white px-4 py-2 rounded-lg'
                                        onClick={() => decrementCount(room.id)}
                                    >
                                        -
                                    </button>
                                    <span className='text-lg font-medium'>{room.roomName === 'Executive Room' ? countExecutive : room.roomName === 'Club Room' ? countClub : room.roomName === 'Superior Room' ? countSuperior : room.roomName === 'Penthouse Suite' ? countPenthouse : 0}</span>
                                    <button
                                        className='bg-[#008080] text-white px-4 py-2 rounded-lg'
                                        onClick={() => incrementCount(room.id)}
                                    >
                                        +
                                    </button>
                                </div>

                                {getRoomAvailability(room.id) > 0 ? (
                                    <button
                                        className='flex items-center bg-[#008080] text-white shadow-custom rounded-lg px-[3%] mt-[5%] mb-[4%] lg:mb-0 hover:scale-110'
                                        onClick={() => handleSelectedRoom(room)}
                                    >
                                        Book Now
                                    </button>
                                ) : (
                                    <button className='flex items-center bg-[#656970] text-white shadow-custom rounded-lg px-[3%] mt-[5%] mb-[4%] lg:mb-0' disabled>Sold Out</button>
                                )}
                            </div>

                        </div>
                    ))}
                </div>
            </div>
            <br />
            <WebFooter />
        </div>
    );
};

export default OfflineBooking;