import React, { useState } from 'react';
import './WebHeader.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from "antd";
import { FaRegUser } from "react-icons/fa";
import { useAuth } from '../../AuthContext';


const WebHeader = () => {

    const { user } = useAuth();
    const username = user.user;
    const navigate = useNavigate();
    const location = useLocation();

    const handleDashboard = () => {
        setMenuOpen(false);
        setIsChanged(false);
        navigate('/Dashboard');
    };

    const handleOfflineBooking = () => {
        setMenuOpen(false);
        setIsChanged(false);
        navigate('/OfflineBooking');
    };

    const handleAllReservation = () => {
        setMenuOpen(false);
        setIsChanged(false);
        navigate('/AllReservation');
    };

    const [isChanged, setIsChanged] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleToggleChange = () => {
        setIsChanged(prevState => !prevState);
        setMenuOpen(!menuOpen);
    };

    const [isForm_UserDetail, setForm_UserDetail] = useState(false);

    const openForm_UserDetail = () => {
        setForm_UserDetail(true);
    };

    const closeForm_UserDetail = () => {
        setIsUserActive(!isUserActive);
        setForm_UserDetail(false);
    };

    const [isUserActive, setIsUserActive] = useState(false);

    const handleUserClick = () => {
        setIsUserActive(!isUserActive);
        openForm_UserDetail();
    };

    const handleLogout = () => {
        try {
            sessionStorage.removeItem('user');
            window.location.reload(true);
            window.location.href = '/Login'
        } catch (error) {
            console.error('Error removing user data from localStorage:', error);
        }
    }

    const isActive = (path) => location.pathname === path;

    return (
        <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <div className='bg-white flex items-center justify-between shadow-custom h-[70px] 2xl:h-[80px] px-[3%] lg:px-[1.5%] w-full ' style={{ position: 'fixed' }}>

                <div className='lg:hidden'>
                    <div className={isChanged ? "changebar" : ""} onClick={handleToggleChange} style={{ position: 'relative', zIndex: 100 }}>
                        <div className="menubar1"></div>
                        <div className="menubar2"></div>
                        <div className="menubar3"></div>
                    </div>
                </div>

                <div className='menu_bg h-[100vh] w-[100vw]' style={{ left: menuOpen ? 0 : '-100vw', top: 0, transition: 'left 0.5s ease-in-out', zIndex: 90, position: 'fixed' }}>
                    <div className='text-lg md:text-2xl lg:text-xl 2xl:text-2xl flex flex-col items-center mt-[10%] md:mt-[5%]'>
                        <a href="/">
                            <img className='h-24 w-24 md:h-36 md:w-36'
                                src="../assets/rpj_hotel_logo.webp" alt="Rpj-Logo" />
                        </a>

                        <div className='flex flex-col items-center bg-white mt-[5%] md:mt-[3%]'>

                            <div>
                                <p>{username.username}</p>
                                <p className='text-gray-400 font-thin'>1234567890</p>
                                <p className='font-thin lg:text-sm 2xl:text-xl'>Reward points: 2465</p>
                            </div>

                            <div className='flex flex-col mt-[2vw] text-gray-700'>
                                <button
                                    className={`border my-[2.5%] px-3 py-2 rounded-lg ${isActive('/Dashboard') ? 'bg-teal-600 text-white' : 'hover:border-[#008080] hover:text-black'}`}
                                    onClick={handleDashboard}
                                >
                                    ANALYTICS
                                </button>

                                <button
                                    className={`border my-[2.5%] px-3 py-2 rounded-lg ${isActive('/OfflineBooking') ? 'bg-teal-600 text-white' : 'hover:border-[#008080] hover:text-black'}`}
                                    onClick={handleOfflineBooking}
                                >
                                    OFFLINE BOOKING
                                </button>

                                <Tooltip title={<><Link to='/CompanyDatabase' className='hover:underline' style={{ color: 'white' }}>Company Database</Link><br /><Link to='/UserDatabase' className='hover:underline' style={{ color: 'white' }}>User Database</Link><br /><Link to='/RoomDatabase' className='hover:underline' style={{ color: 'white' }}>Room Database</Link><br /><Link to='/RevenueDatabase' className='hover:underline' style={{ color: 'white' }}>Revenue Database</Link></>} placement="top">
                                    <div
                                    className={`border my-[2.5%] px-3 py-2 rounded-lg text-center ${isActive('/CompanyDatabase') || isActive('/UserDatabase') || isActive('/RoomDatabase') || isActive('/RevenueDatabase')? 'bg-teal-600 text-white' : 'hover:border-[#008080] hover:text-black'}`}
                                    >
                                        ALL DATABASE
                                    </div>
                                </Tooltip>

                                <button
                                    className={`border my-[2.5%] px-3 py-2 rounded-lg ${isActive('/AllReservation') ? 'bg-teal-600 text-white' : 'hover:border-[#008080] hover:text-black'}`}
                                    onClick={handleAllReservation}
                                >
                                    ALL RESERVATIONS
                                </button>

                                <button
                                    className='border hover:border-[#008080] hover:text-black my-[2%] 2xl:my-[3%] px-3 py-2 2xl:py-3 rounded-lg mt-[10vw] 2xl:mt-[16vw]'
                                    onClick={handleLogout}
                                >
                                    LOGOUT
                                </button>
                            </div>

                        </div>

                    </div>
                </div>

                <a href='/Home'>
                    <img
                        className='lg:ml-[45vw] h-24 w-24 lg:h-28 lg:w-28 2xl:h-36 2xl:w-36'
                        src="../assets/rpj_hotel_logo.webp" alt="RPJ-Logo"
                    />
                </a>

                <div className='flex items-center '>
                    <div class="">
                        <button
                            className={`flex items-center mr-1 lg:mr-5 ${isUserActive ? 'border-teal-600 ring-2 ring-teal-600 p-1 rounded-full' : 'ring-white ring-1 p-1 rounded-full border-transparent'}`}
                            onClick={handleUserClick}                        >
                            <FaRegUser className='h-5 w-5 lg:h-auto lg:w-auto' />
                        </button>
                    </div>
                    {isForm_UserDetail && (
                        <>
                            <div className="overlay_UserDetail" onClick={closeForm_UserDetail}></div>
                            <div
                                className="popup_form_UserDetail"   >
                                <span className="close_UserDetail" onClick={closeForm_UserDetail}>&times;</span>
                                <p>Hello,</p>
                                <p><b>{username.username}</b></p><br />
                                <form>
                                    <div>
                                        <p>Member Status: <b>{username.role.toUpperCase()}</b></p>
                                        <p>Reward points: <b>{username.reward_points}</b></p>

                                        <div className='flex flex-col mt-[15%]'>
                                            <button className='py-[1%] border border-gray-500 hover:border-[#008080] hover:text-[#008080] border-b-0 border-r-0 border-l-0'>
                                                Go to My Account
                                            </button>
                                            <button className='py-[1%] border border-gray-500 hover:border-[#008080] hover:text-[#008080] border-b-0 border-r-0 border-l-0'>
                                                My Bookings
                                            </button>
                                            <button className='py-[1%] border border-gray-500 hover:border-[#008080] hover:text-[#008080] border-b-0 border-r-0 border-l-0'>
                                                Help & Support
                                            </button>

                                            <button onClick={handleLogout} className='text-center border border-gray-500 text-gray-500 hover:text-black hover:border-black mt-[20%] rounded-lg' >LOGOUT</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </>
                    )}

                    <div className='hidden lg:block'>
                        <div className={`flex items-center bg-[#692B32] text-white rounded-lg px-4 py-1 max-w-full responsive-div`}>
                            <p className='font-thin mx-1'>Status&nbsp;<span className='font-semibold mx-1'>ADMIN</span></p>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
};

export default WebHeader;