import React from 'react';

const WebFooter = () => {
    return (
        <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <div className='bg-white flex items-center justify-center bottom-0 h-[50px] w-full lg:ml-[20%] lg:fixed' style={{zIndex: 10}}>
                <a href='https://www.inittechnology.co/' target="_blank" rel="noreferrer"className='lg:-ml-[20%]'>Copyright © Init Technology 2024</a>
            </div>            
        </div>
    );
};

export default WebFooter;