import React, { useCallback, useEffect, useState } from 'react';
import './Dashboard.css';
import WebHeader from '../WebHeader/WebHeader';
import Sidebar from '../Sidebar/Sidebar';
import WebFooter from '../WebFooter/WebFooter';
import { HiArrowLongRight } from "react-icons/hi2";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Link } from 'react-router-dom';
import { API_URL } from '../../Config';

const localizer = momentLocalizer(moment);

const Dashboard = () => {

  const [bookings, setBookings] = useState([]);
  const [company, setCompany] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);


  const handleSelect = ({ start, end }) => {
    const title = window.prompt('Create Booking');
    if (title) {
      setBookings([
        ...formattedBookings,
        {
          title,
          checkIn: start,
          checkOut: end
        }
      ]);
    }
  };

  const fetchCompanies = useCallback(async () => {
    try {
      const response = await fetch(API_URL + '/getCompanies', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      let data = await response.json();

      setCompany(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }, []);

  const fetchBookings = useCallback(async () => {
    try {
      const response = await fetch(API_URL + '/reservationDetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          role: 'admin'
        }),
      });
      let data = await response.json();

      setBookings(data);
      await calculateTotalAmount(data)
      localStorage.setItem('bookings', JSON.stringify(data));
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  }, []);

  useEffect(() => {
    fetchBookings();
    fetchCompanies();
  }, [fetchBookings, fetchCompanies]);

  const calculateTotalAmount = async (bookings) => {
    const total = bookings.reduce((sum, booking) => sum + (booking.total || 0), 0);
    setTotalRevenue(total);
  };

const formattedBookings = bookings.length > 0 ? bookings.map(booking => ({
  ...booking,
  checkIn: new Date(booking.checkIn),
  checkOut: new Date(booking.checkOut),
  title: `${booking.companyName} - ${booking.name}`
})) : [];
  return (
    <div className='bg-[#F8F6F6] text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
      <WebHeader />

      <div className='hidden lg:block'>
        <Sidebar />
      </div>
      <br />

      <div className='mt-[70px] mx-[3%] lg:ml-[23%] 2xl:mb-[6%]'>
        <div className='grid grid-cols-1 lg:grid-cols-3'>
          <div className='dashboard_img_Classic rounded-lg p-[3%] text-white m-[3%]'>
            <p className='font-thin'>TOTAL COMPANY PROFILES</p>
            <p className='mt-[8%] font-semibold text-xl md:text-2xl 2xl:text-3xl'>{company.length || 0}</p>
            <hr className='mt-[8%] mb-[2%]' />
            <Link to='/CompanyDatabase' className='flex items-center hover:underline'>View More Details &nbsp;&nbsp;<HiArrowLongRight /></Link>
          </div>

          <div className='dashboard_img_Silver rounded-lg p-[3%] text-white m-[3%]'>
            <p className='font-thin'>TOTAL COMPANY BOOKINGS</p>
            <p className='mt-[8%] font-semibold text-xl md:text-2xl 2xl:text-3xl'>{bookings.length || 0}</p>
            <hr className='mt-[8%] mb-[2%]' />
            <Link to='/AllReservation' className='flex items-center hover:underline'>View More Details &nbsp;&nbsp;<HiArrowLongRight /></Link>
          </div>

          <div className=' dashboard_img_Gold rounded-lg p-[3%] text-white m-[3%]'>
            <p className='font-thin'>TOTAL REVENUE</p>
            <p className='mt-[8%] font-semibold text-xl md:text-2xl 2xl:text-3xl'>{totalRevenue.toFixed(2) || 0}</p>
            <hr className='mt-[8%] mb-[2%]' />
            <Link to='/RevenueDatabase' className='flex items-center hover:underline'>View More Details &nbsp;&nbsp;<HiArrowLongRight /></Link>
          </div>
        </div>

        <div className='bg-white my-[3%] mx-[3%] lg:mx-[1%] p-[3%] lg:p-[2%] rounded-lg'>
          <p className='mb-[2%]'>ANALYTICS</p>

          <div>
            <Calendar
              localizer={localizer}
              events={formattedBookings}
              selectable
              onSelectSlot={handleSelect}
              startAccessor="checkIn"
              endAccessor="checkOut"
              style={{ height: 500, margin: '0px', zIndex: -100 }}
            />
          </div>
        </div>
      </div>
      <br />
      <WebFooter />
    </div>
  );
};

export default Dashboard;