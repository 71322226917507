import React, { useState } from 'react';
import WebHeader from '../WebHeader/WebHeader';
import Sidebar from '../Sidebar/Sidebar';
import WebFooter from '../WebFooter/WebFooter';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const EditReservation = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const bookingDetails = location.state?.bookingDetails || '';

    const [formData, setFormData] = useState({
        roomName: bookingDetails.roomName || '',
        totalNights: bookingDetails.nights || '',
        mealPlan: bookingDetails.breakfast || '',
        datesBooked: bookingDetails.createdAt || '',
        checkIn: bookingDetails.checkIn || '',
        checkOut: bookingDetails.checkOut || '',
        childAccompanying: bookingDetails.childAccompanying || '',
        bookRide: bookingDetails.bookRide || '',
        price: bookingDetails.price ||'',
        taxes: bookingDetails.taxes || '',
        total: bookingDetails.total || '',
        specialRequest: bookingDetails.specialRequest || '',
        companyName: bookingDetails.companyName || '',
        name: bookingDetails.name || '',
        phone: bookingDetails.phone || '',
        emailID: bookingDetails.email || '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className='bg-[#F8F6F6] text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <WebHeader />

            <div className='hidden lg:block'>
                <Sidebar />
            </div>
            <br />

            <div className='mt-[70px] mx-[3%] lg:ml-[23%]'>

                <Link
                    to="/AllReservation"
                    className='text-[#008080] hover:underline'
                >Go Back</Link>

                <div className='bg-white rounded-lg p-[3%] lg:p-[2%] mb-[2vw]'>
                    <p className='font-semibold'>ALL RESERVATION</p>

                    <p className='mt-[2%] text-lg md:text-2xl lg:text-xl 2xl:text-3xl'>Room Details</p>
                    <hr className='my-[2%]' />

                    <div className='grid grid-cols-1 lg:grid-cols-3'>
                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Room Name*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='roomName' value={formData.roomName} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Total Nights*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='totalNights' value={formData.totalNights} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Meal Plan*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='mealPlan' value={formData.mealPlan} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Dates Booked*</label>
                            <input type="date" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='datesBooked' value={formData.datesBooked} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Check-In*</label>
                            <input type="date" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='checkIn' value={formData.checkIn} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Check-Out*</label>
                            <input type="date" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='checkOut' value={formData.checkOut} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Child Accompanying?*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='childAccompanying' value={formData.childAccompanying === 0 ? 'No' : 'Yes'} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Book a ride?*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='bookRide' value={formData.bookRide === 0 ? 'No' : 'Yes'} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Price*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='price' value={formData.price} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Taxes*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='taxes' value={formData.taxes} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Total*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='total' value={formData.total} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Special Request*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='specialRequest' value={formData.specialRequest} onChange={handleInputChange}/>
                        </div>
                    </div>

                    <p className='mt-[2%] text-lg md:text-2xl lg:text-xl 2xl:text-3xl'>Booking Details</p>
                    <hr className='my-[2%]' />

                    <div className='grid grid-cols-1 lg:grid-cols-2'>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Company Name*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='companyName' value={formData.companyName} onChange={handleInputChange} />
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Name*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='name' value={formData.name} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Phone Number*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='phone' value={formData.phone} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Email ID*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='emailID' value={formData.emailID} onChange={handleInputChange}/>
                        </div>
                    </div>

                    <div className="flex justify-end mt-[2%]">
                        <button className="flex items-center bg-[#008080] text-white shadow-custom rounded-lg px-[3%] hover:scale-110" onClick={() => navigate('/AllReservation')}>
                            Done
                        </button>
                    </div>
                </div>
            </div>
            <br />

            <WebFooter />
        </div>
    );
};

export default EditReservation;
