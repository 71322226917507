import React from 'react';
import WebHeader from '../WebHeader/WebHeader';
import Sidebar from '../Sidebar/Sidebar';
import WebFooter from '../WebFooter/WebFooter';
import { Link, useNavigate } from 'react-router-dom';
import { FaCheck } from "react-icons/fa6";

const SuccessfulBooking = () => {
    const navigate = useNavigate();
    return (
        <div className='bg-[#F8F6F6] text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <WebHeader />

            <div className='hidden lg:block'>
                <Sidebar />
            </div>
            <br />
            <div className='mt-[70px] mx-[3%] lg:ml-[23%]'>
                <Link
                    to="/Dashboard"
                    className='text-[#008080] hover:underline'
                >Go Back</Link>
                <div className='bg-white rounded-lg p-[3%] lg:p-[2%] mb-[16vh] lg:mb-[3vw] 2xl:mb-[34vh]'>
                    <p className=''>STATUS</p>

                    <div className='flex flex-col text-center justify-center mx-[5%] lg:mx-[20%]'>
                        <p className='my-[6%]'>Your booking at RPJ Hotel has been confirmed, and a confirmation mail has been sent to your Email Address.
                        <br/><br/>Thank you for booking with RPJ Hotel. Looking forward to making your stay a memorable experience.</p>

                        <p>Reach out to us for anything at +91 907975379370</p>
                        <FaCheck className='flex mx-auto text-green-600 bg-green-200 rounded-full p-[2%] lg:p-[1.5%] my-[8%] lg:my-[6%] text-7xl lg:text-[100px]' />

                        <div className='flex justify-between mx-[8%] lg:mx-[20%]'>
                            <button className='hover:underline' onClick={() => navigate('/')}>Go to Website</button>

                            <button className='flex items-center rounded-lg font-medium bg-[#008080] text-white hover:text-[#008080] hover:bg-transparent border-[#008080] border-2 hover:border-[#008080] px-[3%] py-[1%] text-sm' onClick={() => navigate('/Dashboard')}>
                                Go&nbsp;to&nbsp;Dashboard
                            </button>
                        </div>
                    </div>

                </div>
            </div>
            <br /><br/>

            <WebFooter />
        </div>
    );
};

export default SuccessfulBooking;