import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Login/Home.css'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { API_URL } from '../../Config';
import { useAuth } from '../../AuthContext';

const Login = () => {

  const [passwordVisible, setPasswordVisible] = useState(false);
  const { dispatch } = useAuth();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const navigate = useNavigate();

  const handleForgotPassword = () => {
    navigate('/ForgotPassword');
  };

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(API_URL + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data.user.role === 'admin') {
          dispatch({ type: 'SIGN_IN', payload: { user: data.user } });
          navigate('/Dashboard');
          setLoading(false);
        } else {
          alert('Access denied. Only admins can sign in.');
          setLoading(false);
        }
      } else if (response.status === 401) {
        alert('Invalid credentials.');
        setLoading(false);
      } else {
        alert('Login failed');
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const currentMonth = new Date().toLocaleString('default', { month: 'long' })

  return (
    <div className='bg-white text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
      <div className='min-h-screen flex flex-col lg:flex-row'>
        <div className='flex-1 mx-auto'>
          <img className='flex mx-auto lg:hidden h-[10em] w-[10em]' src="../assets/rpj_hotel_logo.webp" alt="Logo" />
          {/* <img className='w-full h-full object-contain' src="../assets/webapp/rpj_login_img.webp" alt="Banner-Img" /> */}
          {/* <img className='hidden lg:block w-full h-full object-contain' src="../assets/webapp/rpj_login.webp" alt="Banner-Img" /> */}
          <div className="relative">
            <img className="w-full h-full object-contain" src="../assets/rpj_login_img.webp" alt="Img" />
            <div className="absolute text-center top-[36.5%] right-[29.2%] w-[10%] bg-[#e7f4f7] text-[#6c8af4] text-xs md:text-lg lg:text-xs 2xl:text-lg">
              <p className=''>{currentMonth}</p>
            </div>
          </div>
        </div>

        <div className='flex-1 flex lg:items-center justify-center'>
          <div className='flex flex-col items-center w-full max-w-md 2xl:max-w-xl p-4 lg:p-8'>
            <div className='flex justify-center'>
              <img className='hidden lg:block h-[10em] w-[10em]' src="../assets/rpj_hotel_logo.webp" alt="Logo" />
            </div>

            <form className='w-full' onSubmit={handleSignIn}>
              <p className='text-center font-semibold mb-[10%]'>ADMIN LOGIN</p>

              <div className="mb-4">
                <div className="floating-label-group">
                  <input
                    type="text"
                    placeholder='Email Address/ Phone No./ Member No.'
                    id="username"
                    className="form-control bg-gray-200 rounded w-full px-4 py-2 outline-none focus:ring-2 focus:ring-teal-600"
                    autoComplete="off"
                    autoFocus
                    required
                    name="email" value={formData.email} onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* <div className="border bg-[#CECCCC] rounded-lg my-[5%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600 focus-within:border-2">
                <label className="block text-xs font-medium text-gray-900">Email Address/ Phone No./ Member No.</label>
                <input
                  type="text"
                  className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent"
                  id="username"
                  autoComplete="off"
                  autoFocus
                  required
                  name="email" value={formData.email} onChange={handleInputChange} />
              </div> */}

              {/* <div className="border bg-[#CECCCC] rounded-lg my-[5%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600 focus-within:border-2">
                <label className="block text-xs font-medium text-gray-900">Password</label>
                <div className="relative">
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent"
                    id="password"
                    autoComplete="off"
                    required
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  <span
                    className="eye-icon cursor-pointer absolute right-0 top-1/2 transform -translate-y-1/2"
                    onClick={togglePasswordVisibility}
                  >
                    {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
              </div> */}

              <div className="mb-4">
                <div className="floating-label-group relative">
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    id="password"
                    placeholder='Enter Password'
                    className="form-control bg-gray-200 rounded w-full px-4 py-2 outline-none focus:ring-2 focus:ring-teal-600"
                    autoComplete="off"
                    required
                    name="password" value={formData.password} onChange={handleInputChange}
                  />
                  <span className="eye-icon cursor-pointer absolute right-4 top-1/2 transform -translate-y-1/2" onClick={togglePasswordVisibility}>
                    {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
              </div>

              <div className='flex items-center justify-between mt-4 mx-5'>
                {loading ?
                  <button className="signIn_btn" onClick={handleInputChange} disabled style={{ opacity: 0.5 }}>
                    <span>Sign In</span>
                  </button> :
                  <button className="signIn_btn" onClick={handleInputChange}>
                    <span>Sign In</span>
                  </button>
                }
                <button className='flex hover:underline font-bold' onClick={handleForgotPassword}>Forgot&nbsp;Password?</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;