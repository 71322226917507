import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Login from './components/Login/Login';

const AuthContext = createContext();
const AuthContextForgotPswd = createContext();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, dispatch] = useReducer(authReducer, null);
  const location = useLocation(); // Get the current path

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      dispatch({ type: 'SIGN_IN', payload: { user: JSON.parse(storedUser) } });
    }
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <>
        <div>Loading...</div>
        <br />
        <div className="loader"></div>
      </>
    );
  }

  return (
    <AuthContext.Provider value={{ user, dispatch }}>
      {user === null && location.pathname === '/Login' ? <Login /> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProviderForgotPswd = ({ children }) => {
  return (
    <AuthContextForgotPswd.Provider value={{ user: null }}>
      {children}
    </AuthContextForgotPswd.Provider>
  );
};

export const useAuthForgotPswd = () => {
  const context = useContext(AuthContextForgotPswd);
  if (!context) {
    throw new Error('useAuthForgotPswd must be used within an AuthProviderForgotPswd');
  }
  return context;
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'SIGN_IN':
      sessionStorage.setItem('user', JSON.stringify(action.payload.user));
      return { user: action.payload.user };
    case 'SIGN_OUT':
      sessionStorage.removeItem('user');
      return { user: null };
    default:
      return state;
  }
};
