import React, { useState } from 'react';
import WebHeader from '../WebHeader/WebHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { LuSofa } from "react-icons/lu";
import { MdBalcony } from "react-icons/md";
import { IoWifi } from "react-icons/io5";
import { GiHeatHaze } from "react-icons/gi";
import { GiWashingMachine } from "react-icons/gi";
import { TbAirConditioning } from "react-icons/tb";
import { MdSmokingRooms } from "react-icons/md";
import { LiaDogSolid } from "react-icons/lia";
import { HiOutlineUsers } from "react-icons/hi2";
import { TiTick } from "react-icons/ti";
import Sidebar from '../Sidebar/Sidebar';

const BookRoom = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const check_in = location.state?.check_in || '';
    const check_out = location.state?.check_out || '';
    const roomPrice = location.state?.roomPrice || '';
    const roomDetails = location.state?.roomDetails || '';
    const roomName = roomDetails.roomName || '';
    const roomCount = roomName === 'Executive Room' ? location.state?.countExecutive : roomName === 'Club Room' ? location.state?.countClub : roomName === 'Superior Room' ? location.state?.countSuperior : roomName === 'Penthouse Suite' ? location.state?.countPenthouse : 0
    const adults = location.state?.adults || 1;
    const children = location.state?.children || 0;

    const [selectedPriceOption, setSelectedPriceOption] = useState('withoutBreakfast');
    const [bookRide, setBookRide] = useState(false);
    const [childaccompanying, setChildaccompanying] = useState(false);

    const handleCheckout = () => {

        navigate('/Checkout', {
            state: {
                check_in,
                check_out,
                roomCount,
                roomPrice: selectedPriceOption === 'corporatePlan' ? roomPrice + 699 : selectedPriceOption === 'americanPlan' ? roomPrice + 899 : selectedPriceOption === 'modifiedAmericanPlan' ? roomPrice + 799 : roomPrice,
                bookRide,
                childaccompanying,
                roomDetails,
                selectedPriceOption,
                adults,
                children
            }
        });
    }

    const [selectedImage, setSelectedImage] = useState("../assets/Room-1-View-1.webp");

    const imagess = [
        "../assets/Room-1-View-1.webp",
        "../assets/Room-1-View-6.webp",
        "../assets/Room-1-View-7.webp",
        "../assets/Room-2-View-6.webp"
    ];
    const imagesExecutiveRoom = [
        '../assets/executive_room_img1.webp',
        '../assets/Executive-Room-01.webp',
        '../assets/Executive-Room-02.webp',
        '../assets/Executive-Room-03.webp'
    ];

    const imagesClubRoom = [
        "../assets/Room-1-View-1.webp",
        "../assets/Room-1-View-6.webp",
        "../assets/Room-1-View-7.webp",
        "../assets/Room-2-View-6.webp"
    ];

    const imagesSuperiorRoom = [
        '../assets/superior_room_img1.webp',
        '../assets/Room-2-View-6.webp',
        '../assets/Room 2 View 10.webp',
        '../assets/_MG_6983.webp'
    ];

    const imagesPenthouseRoom = [
        '../assets/penthouse_room_img1.webp',
        '../assets/Penthouse suite 020.webp',
        '../assets/Penthouse suite 017.webp',
        '../assets/Penthouse suite 006.webp'
    ];

    const images = roomName === 'Executive Room' ? imagesExecutiveRoom : roomName === 'Club Room' ? imagesClubRoom : roomName === 'Superior Room' ? imagesSuperiorRoom : roomName === 'Penthouse Suite' ? imagesPenthouseRoom : imagess

    const handleThumbnailClick = (image) => {
        setSelectedImage(image);
    };

    const [showFullContent, setShowFullContent] = useState(false);
    const content = "Lorem ipsum dolor sit amet consectetur. Sit dui fermentum vitae in dui gravida lectus molestie. Dui a netus interdum enim. Vitae id ornare amet curabitur cursus arcu ante sed. Orci ipsum libero sed in eget vitae. Nulla fringilla integer viverra aenean massa. Metus consequat purus enim tortor malesuada morbi. Eget mattis risus mauris egestas. Aenean quis amet habitant tortor facilisis viverra erat viverra velit. Velit mattis magna cursus purus vitae mauris senectus maed";

    const toggleContent = () => {
        setShowFullContent(!showFullContent);
    };


    const [showAll, setShowAll] = useState(false);

    const buttons = [
        { icon: 'LuSofa', text: 'Shared Living Room' },
        { icon: 'MdBalcony', text: 'Balcony' },
        { icon: 'IoWifi', text: 'WiFi' },
        { icon: 'GiHeatHaze', text: 'Heating' },
        { icon: 'GiWashingMachine', text: 'Washing Machine' },
        { icon: 'TbAirConditioning', text: 'Air Conditioner' }
    ];

    const FacilitiesButtons = showAll ? buttons : buttons.slice(0, 4);

    const Icon = ({ icon }) => {
        switch (icon) {
            case 'LuSofa':
                return <LuSofa />;
            case 'MdBalcony':
                return <MdBalcony />;
            case 'IoWifi':
                return <IoWifi />;
            case 'GiHeatHaze':
                return <GiHeatHaze />;
            case 'GiWashingMachine':
                return <GiWashingMachine />;
            case 'TbAirConditioning':
                return <TbAirConditioning />;
            default:
                return null;
        }
    };

    const pricingOptions = [
        {
            id: 'corporatePlan',
            label: 'Corporate Plan (only breakfast)',
            price: 699,
            description: 'Cancellation subject to conditions',
            taxes: 'Taxes & fees not included',
        },
        {
            id: 'americanPlan',
            label: 'American Plan (breakfast, lunch & dinner)',
            price: 799,
            description: 'Cancellation subject to conditions',
            taxes: 'Taxes & fees not included',
        },
        {
            id: 'modifiedAmericanPlan',
            label: 'Modified American Plan (breakfast and lunch or dinner)',
            price: 899,
            description: 'Cancellation subject to conditions',
            taxes: 'Taxes & fees not included',
        },
    ];

    return (
        <div className='bg-[#F8F6F6] text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <WebHeader />

            <div className='hidden lg:block'>
                <Sidebar />
            </div>
            <br />

            <div className='mt-[70px] mx-[3%] lg:ml-[23%]'>

                {/* Grid section starts from here */}
                <div className='mt-[5%] lg:mx-[5%]'>
                    <div className='grid grid-cols-1 lg:grid-cols-2'>

                        <div className='mx-[3%] lg:w-[120%]'>
                            <div className='upper_slider'>
                                <div>
                                    <img className='rounded-xl' src={selectedImage} alt='Img' />
                                </div>
                            </div>
                            <div className='bottom_grid grid grid-cols-4 my-[1%]'>
                                {images.map((image, index) => (
                                    <img
                                        key={index}
                                        className='rounded-lg lg:rounded-xl px-[3%] cursor-pointer'
                                        src={image}
                                        alt={`Thumbnail ${index}`}
                                        onClick={() => handleThumbnailClick(image)}
                                    />
                                ))}
                            </div>

                            <div>
                                <div className='flex justify-between mt-[3%]'>
                                    <p className='text-lg md:text-2xl 2xl:text-3xl'>{roomName}</p>
                                </div>

                                <p className='text-[#6C6B6B] my-[1%]'>Kalavad Rd, Near Everest Park, Jai Bhimnagar, Nana Mava, Rajkot.</p>

                                <div className=''>
                                    <button className='bg-[#D6DBEC] text-[#25409C] rounded-full px-[1%] m-[1%] text-[12px] md:text-sm 2xl:text-lg' disabled>
                                        4&nbsp;bedrooms
                                    </button>

                                    <button className='bg-[#D6DBEC] text-[#25409C] rounded-full px-[1%] m-[1%] text-[12px] md:text-sm 2xl:text-lg' disabled>
                                        150&nbsp;m2
                                    </button>

                                    <button className='bg-[#D6DBEC] text-[#25409C] rounded-full px-[1%] m-[1%] text-[12px] md:text-sm 2xl:text-lg' disabled>
                                        Private&nbsp;Bathroom
                                    </button>

                                    <button className='bg-[#D6DBEC] text-[#25409C] rounded-full px-[1%] m-[1%] text-[12px] md:text-sm 2xl:text-lg' disabled>
                                        Furnished
                                    </button>

                                    <button className='bg-[#D6DBEC] text-[#25409C] rounded-full px-[1%] m-[1%] text-[12px] md:text-sm 2xl:text-lg' disabled>
                                        Cleaning&nbsp;Included
                                    </button>

                                    <button className='bg-[#D6DBEC] text-[#25409C] rounded-full px-[1%] m-[1%] text-[12px] md:text-sm 2xl:text-lg' disabled>
                                        Balcony
                                    </button>

                                    <button className='bg-[#D6DBEC] text-[#25409C] rounded-full px-[1%] m-[1%] text-[12px] md:text-sm 2xl:text-lg' disabled>
                                        Bed&nbsp;Linen/Towels
                                    </button>
                                </div>

                                <div className='border rounded-xl p-[2%] mt-[3%]'>
                                    {pricingOptions.map(option => (
                                        <div key={option.id} className='flex items-center justify-between my-[2%]'>
                                            <div className='flex 2xl:items-center'>
                                                <div className="flex items-center h-5">
                                                    <input
                                                        name="price"
                                                        type="radio"
                                                        value={option.id}
                                                        checked={selectedPriceOption === option.id}
                                                        onChange={() => setSelectedPriceOption(option.id)}
                                                    />
                                                </div>
                                                <div className="flex flex-col ml-3">
                                                    <label className=''>{option.label}</label>
                                                    <label className='text-[12px] md:text-sm 2xl:text-lg leading-4'>{option.description}</label>
                                                </div>
                                            </div>

                                            <div className='flex flex-col'>
                                                <label className='text-[#008080] text-right'>{option.price}</label>
                                                <label className='text-[12px] md:text-sm 2xl:text-lg leading-4 text-right w-[80%] ml-[20%]'>{option.taxes}</label>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>

                        <div className='mx-[3%] mt-[5%] lg:mt-0 lg:ml-[30%]'>

                            <div className='border rounded-xl p-[2%]'>
                                <p className='font-semibold'>Description</p>

                                {showFullContent ? content : `${content.slice(0, 200)}...`}
                                &nbsp;<button className='text-[#008080] bg-[rgb(239,248,255)] rounded-full px-[1%] text-[12px] md:text-sm 2xl:text-lg' onClick={toggleContent}>
                                    {showFullContent ? 'Show Less' : 'Show More'}
                                </button>

                            </div>

                            <div className='border rounded-xl p-[2%] mt-[3%]'>
                                <p className='font-semibold'>Facilities</p>

                                <div className='flex flex-wrap items-center'>
                                    {FacilitiesButtons.map((button, index) => (
                                        <button key={index} className='flex items-center bg-[#F2F4F7] text-[#344054] rounded-full px-[1%] m-[1%] text-[12px] md:text-sm 2xl:text-lg' disabled>
                                            <Icon icon={button.icon} />&nbsp;{button.text}
                                        </button>
                                    ))}
                                    <button onClick={() => setShowAll(!showAll)} className='text-[#008080] bg-[#EFF8FF] rounded-full px-[1%] text-[12px] md:text-sm 2xl:text-lg'>
                                        {showAll ? 'Show Less' : 'Show More'}
                                    </button>
                                </div>

                            </div>

                            <div className='border rounded-xl p-[2%] mt-[3%]'>
                                <p className='font-semibold'>Hotel Rules</p>

                                <div className='flex flex-wrap'>
                                    <button className='flex items-center bg-[#F2F4F7] text-[#344054] rounded-full px-[1%] m-[1%] text-[12px] md:text-sm 2xl:text-lg' disabled>
                                        <MdSmokingRooms />&nbsp;Smoking&nbsp;not&nbsp;allowed
                                    </button>

                                    <button className='flex items-center bg-[#F2F4F7] text-[#344054] rounded-full px-[1%] m-[1%] text-[12px] md:text-sm 2xl:text-lg' disabled>
                                        <LiaDogSolid />&nbsp;Pets&nbsp;are&nbsp;not&nbsp;allowed
                                    </button>

                                    <button className='flex items-center bg-[#F2F4F7] text-[#344054] rounded-full px-[1%] m-[1%] text-[12px] md:text-sm 2xl:text-lg' disabled>
                                        <HiOutlineUsers />&nbsp;Overnight&nbsp;guests&nbsp;are&nbsp;allowed
                                    </button>
                                </div>

                            </div>

                            <div className='border rounded-lg p-[2%] mt-[3%] '>
                                <div className='flex items-center bg-[#F8F9FC] rounded-full px-[2%] py-[1%] my-[2%]'>
                                    <TiTick className='bg-[#008080] rounded-full text-white' />&nbsp;Professional Team
                                </div>

                                <div className='flex items-center bg-[#F8F9FC] rounded-full px-[2%] py-[1%] my-[2%]'>
                                    <TiTick className='bg-[#008080] rounded-full text-white' />&nbsp;Book a Call
                                </div>

                                <div className='flex items-center bg-[#F8F9FC] rounded-full px-[2%] py-[1%] my-[2%]'>
                                    <TiTick className='bg-[#008080] rounded-full text-white' />&nbsp;Verified Room
                                </div>

                                <div className='flex items-center bg-[#F8F9FC] rounded-full px-[2%] py-[1%] my-[2%]'>
                                    <TiTick className='bg-[#008080] rounded-full text-white' />&nbsp;Fast and Secure Booking
                                </div>
                            </div>

                            <div className='border rounded-lg shadow-custom p-[2%] mt-[3%]'>

                                <div className='flex justify-between'>
                                    <p className='font-semibold'>Booking Request</p>
                                    {/* <button><FiShare2 /></button> */}
                                </div>

                                <div className='grid grid-cols-2'>
                                    <div className='flex flex-col my-[1%] mx-[2%]'>
                                        <label className='text-[12px] md:text-sm 2xl:text-lg font-semibold'>Check In</label>
                                        <label className='border bg-transparent rounded-lg outline-[#008080] p-[1%]'>{check_in}</label>
                                    </div>

                                    <div className='flex flex-col my-[1%] mx-[2%]'>
                                        <label className='text-[12px] md:text-sm 2xl:text-lg font-semibold'>Check Out</label>
                                        <label className='border bg-transparent rounded-lg outline-[#008080] p-[1%]'>{check_out}</label>
                                    </div>
                                </div>
                                {/* <button className='bg-[#008080] text-white w-full rounded-lg mt-[2%] hover:bg-white hover:text-[#008080] border-2 border-[#008080]'>Check Booking</button> */}

                            </div>

                            <div className='border shadow-custom rounded-lg p-[2%] mt-[3%]'>

                                <div className='flex items-center justify-between'>
                                    <div className="flex items-center h-5">
                                        <label className='font-semibold'>Book a Ride</label>
                                    </div>

                                    <div>
                                        <input name="bookRide" type="checkbox" checked={bookRide} onChange={() => setBookRide(!bookRide)} />
                                    </div>
                                </div>
                            </div>
                            <div className='border shadow-custom rounded-lg p-[2%] mt-[3%]'>

                                <div className='flex items-center justify-between'>
                                    <div className="flex items-center h-5">
                                        <label className='font-semibold'>Child accompanying?</label>
                                    </div>

                                    <div>
                                        <input name="childaccompanying" type="checkbox" checked={childaccompanying} onChange={() => setChildaccompanying(!childaccompanying)} />
                                    </div>
                                </div>
                            </div>

                            <button
                                className='bg-[#008080] text-white w-full rounded-full mt-[3%] hover:bg-transparent hover:text-[#008080] border-2 border-[#008080]'
                                onClick={handleCheckout}
                            >
                                Checkout
                            </button>

                        </div>
                    </div>
                </div>
                {/* Grid section ends here */}

            </div>
            <br /><br />
        </div>
    );
};



export default BookRoom;
