import React, { useState } from 'react';
import WebHeader from '../../WebHeader/WebHeader';
import Sidebar from '../../Sidebar/Sidebar';
import WebFooter from '../../WebFooter/WebFooter';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const EditUserDatabase = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const userDetails = location.state?.userDetails || '';

    const [formData, setFormData] = useState({
        companyName: userDetails.companyName || '',
        membershipID: userDetails.memberId || '',
        membershipStatus: userDetails.memberStatus || '',
        name: userDetails.name || '',
        emailID: userDetails.email ||  '',
        telephoneNumber: userDetails.phone ||  '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className='bg-[#F8F6F6] text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <WebHeader />

            <div className='hidden lg:block'>
                <Sidebar />
            </div>
            <br />

            <div className='mt-[70px] mx-[3%] lg:ml-[23%]'>

                <Link
                    to="/UserDatabase"
                    className='text-[#008080] hover:underline'
                >Go Back</Link>

                <div className='bg-white rounded-lg p-[3%] lg:p-[2%] mb-[2vw] lg:h-[74vh]'>
                    <p className='font-semibold'>USER DATABASE - CHECKOUTS</p>

                    <p className='mt-[2%] text-lg md:text-2xl lg:text-xl 2xl:text-3xl'>User Database Details</p>
                    <hr className='my-[2%]' />

                    <div className='grid grid-cols-1 lg:grid-cols-2'>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Company Name*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='companyName' value={formData.companyName} onChange={handleInputChange} readOnly/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Membership ID*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='membershipID' value={formData.membershipID} onChange={handleInputChange}  readOnly/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Membership Status*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='membershipStatus' value={formData.membershipStatus} onChange={handleInputChange} readOnly/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Name*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='name' value={formData.name} onChange={handleInputChange} readOnly/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Email ID*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='emailID' value={formData.emailID} onChange={handleInputChange} readOnly/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Contact Number*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='telephoneNumber' value={formData.telephoneNumber} onChange={handleInputChange} readOnly/>
                        </div>
                    </div>
                    <div className="flex justify-end mt-[2%]">
                        <button className="flex items-center bg-[#008080] text-white shadow-custom rounded-lg px-[3%] hover:scale-110" onClick={() => navigate('/UserDatabase')}>
                            Done
                        </button>
                    </div>
                </div>
            </div>
            <br />
            <WebFooter />
        </div>
    );
};

export default EditUserDatabase;