import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import OfflineBooking from './components/OfflineBooking/OfflineBooking';
import BookRoom from './components/BookRoom/BookRoom';
import Checkout from './components/Checkout/Checkout';
import CompanyDatabase from './components/AllDatabase/CompanyDatabase/CompanyDatabase';
import UserDatabase from './components/AllDatabase/UserDatabase/UserDatabase';
import RoomDatabase from './components/AllDatabase/RoomDatabase/RoomDatabase';
import RevenueDatabase from './components/AllDatabase/RevenueDatabase/RevenueDatabase';
import AllReservation from './components/AllReservation/AllReservation';
import EditCompanyDatabase from './components/AllDatabase/CompanyDatabase/EditCompanyDatabase';
import AddCompanyDatabase from './components/AllDatabase/CompanyDatabase/AddCompanyDatabase';
import EditUserDatabase from './components/AllDatabase/UserDatabase/EditUserDatabase';
import EditRoomDatabase from './components/AllDatabase/RoomDatabase/EditRoomDatabase';
import EditReservation from './components/AllReservation/EditReservation';
import SuccessfulBooking from './components/SuccessfulBooking/SuccessfulBooking';
import UnsuccessfulBooking from './components/UnsuccessfulBooking/UnsuccessfulBooking';
import { AuthProvider, useAuth } from './AuthContext';
import Login from './components/Login/Login';

function App() {
  function ProtectedRoute({ element }) {
    const { user } = useAuth();
    return user ? element : <Navigate to="/Login" />;
  }
  return (
    <div>
      <Router>
      <AuthProvider>
       <Suspense>
        <Routes>
          <Route path='/' element={<ProtectedRoute element={<Dashboard />} />}/>
          <Route path='/Dashboard' element={<ProtectedRoute element={<Dashboard />} />}/>
          <Route path='/Login' element={<ProtectedRoute element={<Login />} />}/>
          <Route path='/OfflineBooking' element={<ProtectedRoute element={<OfflineBooking />} />}/>
          <Route path='/BookRoom' element={<ProtectedRoute element={<BookRoom />} />}/>
          <Route path='/Checkout' element={<ProtectedRoute element={<Checkout />} />}/>
          <Route path='/CompanyDatabase' element={<ProtectedRoute element={<CompanyDatabase />} />}/>
          <Route path='/UserDatabase' element={<ProtectedRoute element={<UserDatabase />} />}/>
          <Route path='/RoomDatabase'  element={<ProtectedRoute element={<RoomDatabase />} />}/>
          <Route path='/RevenueDatabase' element={<RevenueDatabase element={<ProtectedRoute element={<RevenueDatabase />} />}/>} />
          <Route path='/AllReservation' element={<ProtectedRoute element={<AllReservation />} />}/>
          <Route path='/EditCompanyDatabase' element={<ProtectedRoute element={<EditCompanyDatabase />} />}/>
          <Route path='/AddCompanyDatabase' element={<ProtectedRoute element={<AddCompanyDatabase />} />}/>
          <Route path='/EditUserDatabase' element={<ProtectedRoute element={<EditUserDatabase />} />}/>
          <Route path='/EditRoomDatabase' element={<ProtectedRoute element={<EditRoomDatabase />} />} />
          <Route path='/EditReservation' element={<ProtectedRoute element={<EditReservation />} />}/>
          <Route path='/SuccessfulBooking' element={<ProtectedRoute element={<SuccessfulBooking />} />}/>
          <Route path='/UnsuccessfulBooking' element={<ProtectedRoute element={<UnsuccessfulBooking />} />}/>

        </Routes> 
        </Suspense> 
       </AuthProvider>
      </Router>    
    </div>
  );
}

export default App;
