import React, { useState } from 'react';
import 'flatpickr/dist/flatpickr.min.css';
import { Link, useNavigate } from 'react-router-dom';
import WebHeader from '../../WebHeader/WebHeader';
import Sidebar from '../../Sidebar/Sidebar';
import WebFooter from '../../WebFooter/WebFooter';
import { useLocation } from 'react-router-dom';
import { API_URL } from '../../../Config';

const EditCompanyDatabase = () => {
   
    const location = useLocation();
    const companyDetails = location.state?.companyDetails || '';
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        companyName: companyDetails.companyName || '',
        username: companyDetails.username || '',
        membershipStatus: companyDetails.status || '',
        panNumber: companyDetails.company_PAN || '',
        partnerName: companyDetails.owner_name || '',
        emailID: companyDetails.email || '',
        telephoneNumber: companyDetails.mobile || '',
        faxNumber: companyDetails.fax_number || '',
        companyRoomDiscount: companyDetails.room_discount || 0,
        totalRewardPoints: companyDetails.reward_points || '',
        address: companyDetails.address || '',
        postcode: companyDetails.pincode || '',
        city: companyDetails.city || '',
        country: companyDetails.country || '',
        bankName: companyDetails.bank_name ||'',
        branchAddress: companyDetails.bank_branch ||'',
        accountNumber: companyDetails.bank_account ||'',
        typeOfAccount: companyDetails.account_type ||'',
        password: companyDetails.password || '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    
    const updateCompanyDetails = async () => {
        try {
            const response = await fetch(API_URL + `/updateCompanyDetails/${companyDetails.memberId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                console.log('Company details updated successfully');
                window.alert('Company details updated successfully!');
                navigate('/CompanyDatabase');
            } else {
                console.error('Error updating company details');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className='bg-[#F8F6F6] text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <WebHeader />

            <div className='hidden lg:block'>
                <Sidebar />
            </div>
            <br />

            <div className='mt-[70px] mx-[3%] lg:ml-[23%]'>

                <Link
                    to="/CompanyDatabase"
                    className='text-[#008080] hover:underline'
                >Go Back</Link>

                <div className='bg-white rounded-lg p-[3%] lg:p-[2%] mb-[2vw]'>
                    <p className='font-semibold'>COMPANY PROFILE DATABASE</p>

                    <p className='mt-[2%] text-lg md:text-2xl lg:text-xl 2xl:text-3xl'>Company Profile</p>
                    <hr className='my-[2%]' />

                    <div className='grid grid-cols-1 lg:grid-cols-2'>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Company Name*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='companyName' value={formData.companyName} onChange={handleInputChange} />
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Username*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='username' value={formData.username} onChange={handleInputChange} />
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Membership Status*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='membershipStatus' value={formData.membershipStatus} onChange={handleInputChange} />
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Company/ Firm PAN No.*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='panNumber' value={formData.panNumber} onChange={handleInputChange} />
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Name of Proprietor/ Partner/ Directors*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-300 focus:ring-0 outline-none bg-transparent" placeholder='Add more names, each followed by a comma' name='partnerName' value={formData.partnerName} onChange={handleInputChange} />
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Email ID*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='emailID' value={formData.emailID} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Telephone Number*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='telephoneNumber' value={formData.telephoneNumber} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Fax Number</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='faxNumber' value={formData.faxNumber} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Password</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='password' value={formData.password} onChange={handleInputChange}/>
                        </div>
                    </div>

                    <p className='mt-[3%] font-semibold'>ROOM DISCOUNTS & MORE</p>
                    <hr className='my-[2%]' />

                    <div className='grid grid-cols-1 lg:grid-cols-4'>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Company Room Discount %*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='companyRoomDiscount' value={formData.companyRoomDiscount} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Total Reward Points*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='totalRewardPoints' value={formData.totalRewardPoints} onChange={handleInputChange} />
                        </div>
                    </div>

                    <p className='mt-[3%] font-semibold'>HEAD OFFICE ADDRESS</p>
                    <hr className='my-[2%]' />

                    <div className='grid grid-cols-1 lg:grid-cols-3'>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Address*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='address' value={formData.address} onChange={handleInputChange} />
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Postcode*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='postcode' value={formData.postcode} onChange={handleInputChange} />
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">City*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='city' value={formData.city} onChange={handleInputChange}/>
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Country*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='country' value={formData.country} onChange={handleInputChange}/>
                        </div>

                    </div>

                    <p className='mt-[3%] font-semibold'>ABOUT BANK</p>
                    <hr className='my-[2%]' />

                    <div className='grid grid-cols-1 lg:grid-cols-3'>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Bank Name*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='bankName' value={formData.bankName} onChange={handleInputChange} />
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Branch Address*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='branchAddress' value={formData.branchAddress} onChange={handleInputChange} />
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Account No.*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='accountNumber' value={formData.accountNumber} onChange={handleInputChange} />
                        </div>

                        <div class="border bg-white rounded-lg my-[2%] lg:mx-[3%] px-[3%] py-[1%] h-[3.5em] focus-within:border-teal-600">
                            <label class="block text-xs font-medium text-gray-900">Type of Account*</label>
                            <input type="text" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 outline-none bg-transparent" name='typeOfAccount' value={formData.typeOfAccount} onChange={handleInputChange} />

                        </div>
                    </div>

                    <div className="flex justify-end mt-[2%]">
                        <button className="flex items-center bg-[#008080] text-white shadow-custom rounded-lg px-[3%] hover:scale-110" onClick={() => updateCompanyDetails()}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <br />

            <WebFooter />
        </div>
    );
};

export default EditCompanyDatabase;
